// Colors

$primary: #000000;
$primary-text: #ffffff;
$secondary: #05eeff;
$contrast: #96989a;
$contrast-dark: #333333;
$background: #191919;

// Sizes

// Fonts
$font-family-oswald:
  'Oswald',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;
$font-family-metropolis:
  'Metropolis',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;
$fontSizeTitleWeb: 140%;
$fontSizeTitleMobile: 100%;

:root {
  --primary: #{$primary};
  --primary-text: #{$primary-text};
  --secondary: #{$secondary};
  --contrast: #{$contrast};
  --contrast-dark: #{$contrast-dark};
  --background: #{$background};

  --font-family-oswald: #{$font-family-oswald};
  --font-family-metropolis: #{$font-family-metropolis};
}

// Breakpoints
$breakpoint-desktop: 1200px;
$breakpoint-tablet: 960px;
$breakpoint-mobile: 640px;

$font-awesome-icon-color: #98999d;

// Box shadows
$navigation-box-shadow:
  0 5px 20px rgba(0, 0, 0, 0.15),
  0 3px 6px rgba(0, 0, 0, 0.17);

// Old variables
$white: #ffffff;
$black: #000000; //#191919
$dark-black: #000000;
$dark-blue: $primary;
$light-blue: #3679d0;
$light-green: #63c234;
$light-red: #d92e37;
$brown: $secondary;
$brownish: #bc9f5d;
$gray: $contrast;
$teal: #43516c;
$star-exclusive-text: #b9c1dd;
$star-exclusive-watch-btn: #009cff;
$star-exclusive-watch-btn-text: #04236a;
$top-ten-background: #121212;
$sign-in-error-background: #017ad5;

$teal-green: #0ab9d3;
$blueish: #0996ed;
$grayish: #8f95a1;

$modal-button-color: #014d89;

$default-dark-blue: #00295d;

$default-spacing: 10px;

$purple-dark: #51576c;
$purple-light: #7a7de5;
$rebeca-purple: #663399;
$player-background: #2b333f;
$player-user-rated-movie-positive: #39ff14;
$player-user-rated-movie-negative: #ff0000;
$player-add-menu-background: #404040;

$default-line-separator: #0a0a0a;

$title-font-size: calc(16px + 0.2vw);
$subtitle-font-size: calc(16px + 0.1vw);

$hint-font-size: calc(10px + 0.2vw);

$arrow-clear: 0.3em solid transparent;

$arrow-color: 0.4em solid $dark-blue;

$videoDetailstItems: $secondary;

$playlistBlue: #184285;

$playlistDarkBlue: #112d5d;

$ageVerifiedIcon: #00cc00;
 
.MainDashboardHeader {
  @media (max-width: $breakpoint-tablet) {
    background-color: $white;

    .MobileSearchBtn {
      color: $black;
    }
  }
} 
 
$chatColor: #7fdde9;