@import '../../variables';

.MovieOverlay {
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, $dark-black, transparent, transparent);
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    left: 0;
    width: 40%;
    height: 100%;
    z-index: -1;
  }

  .TextWrapper {
    padding: 1em;
    max-width: 500px;
    font-family: $font-family-metropolis;
    font-size: 1.4rem;

    @media(min-width: 1600px) {
      font-size: 1.6rem;
    }

    @media(min-width: $breakpoint-tablet) {
      padding: 2em 2em 2em 1em;
    }

    .TopText {
      color: $gray;
    }

    .MiddleText {
      margin: .5em 0;
      font-size: 120%;
      font-weight: 900;
      line-height: 0.95;
    }

    .BottomText {
      font-size: 85%;
      text-transform: capitalize;
      font-weight: 600;
      color: $secondary;
    }

    .VideoBtn {
      background-color: $brown;
      border-radius: 10px;
      border: none;
      text-transform: uppercase;
      text-align: center;
      padding: 10px 40px;
      cursor: pointer;
      font-family: $font-family-oswald;
      font-size: 1.2rem;
      font-weight: bold;
      color: $dark-black;
      display: inline-block;
      white-space: nowrap;
      max-width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media(min-width: $breakpoint-tablet) {
        width: 100%;
        max-width: 280px;
        margin: 1.5em 0;
      }

      @media(max-width: $breakpoint-tablet) {
        position: absolute;

        &.TopLeft {
          top: 1em;
          left: 1em;
        }

        &.TopRight {
          top: 1em;
          right: 1em;
        }

        &.BottomLeft {
          bottom: 1em;
          left: 1em;
        }

        &.BottomRight {
          bottom: 1em;
          right: 1em;
        }

        &.Center {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:hover {
        opacity: .9;
        color: $white;
      }
    }
  }
}
