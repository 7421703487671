@import '../../../variables';
.JustAddedComponent {
  display: none;
  visibility: hidden;
}

.BannerSetView.Banner-69 {
  display: none;
  visibility: hidden;
}

.FeaturedRelease {
  display: flex;
  width: 100%;
  padding-top: 0;

  .FeaturedRelease-Title {
    display: none;
  }
}

.TopTen {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.MembershipSection {
  display: none;
}










//locking qreel video in

@media only screen and (max-width: $breakpoint-mobile) {

  .qreelvideo-container {
    height: 100%;
    width: 100%;
    max-height: 140px;
    overflow: hidden;
    position: relative;
    min-height: 140px;

    .qreelvideotextwrap {
      position: absolute;
      top: 36%;
      left: 10%;
      width: 100%;
      z-index: 3;
    }
    .qreelvideotextone {
      color: $white;
      font-size: 1.05rem;
      text-transform: uppercase;
      display: none;
      visibility: hidden;
    }

    .qreelvideotextmain {
      color: $secondary;
      font-size: 1.05rem;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .qreelvideotextthree {
      padding-top: 5px;
      color: $contrast;
      text-transform: uppercase;
      display: none;
      visibility: hidden;
    }

    .BaseVideoPlayerCore .vjs-playlist-player-container {
      position:absolute;
      top: -165px;
      height: 500px;
      overflow: hidden;
    }
  } // qreel container
} // end mobile









@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-desktop) {


  .qreelvideo-container {
    height: 100%;
    width: 100vw;
    max-height: 250px;
    overflow: hidden;
    position: relative;
    min-height: 250px;

    .qreelvideotextwrap {
      position: absolute;
      top: 25%;
      left: 10%;
      width: 100%;
      z-index: 3;
    }
    .qreelvideotextone {
      color: $white;
      font-size: 1.25rem;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .qreelvideotextmain {
      color: $secondary;
      font-size: 2.5rem;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .qreelvideotextthree {
      padding-top: 5px;
      color: $contrast;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .BaseVideoPlayerCore .vjs-playlist-player-container {
      position:absolute;
      top: -160px;
      height: 650px;
      overflow: hidden;
    }





  } // qreel container
} // end tablet



@media only screen and (min-width: $breakpoint-desktop) {


  .qreelvideo-container {
    height: auto;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    position: relative;
    min-height:400px;


    .qreelvideotextwrap {
      position: absolute;
      top: 36%;
      left: 10%;
      width: 100%;
      z-index: 3;
    }
    .qreelvideotextone {
      color: $white;
      font-size: 1.25rem;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .qreelvideotextmain {
      color: $secondary;
      font-size: 2.5rem;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .qreelvideotextthree {
      padding-top: 5px;
      color: $contrast;
      text-transform: uppercase;
      display: block;
      visibility: visible;
    }

    .BaseVideoPlayerCore .vjs-playlist-player-container {
      position:absolute;
      top: -200px;
      height: 950px;
      overflow: hidden;
    }


  } // qreel container
} // end desktop

@media only screen and (min-width: 1700px) {
  /* Fade Overlay Elements */
  .left-fade,
  .right-fade {
    position: absolute;
    top: 0;
    width: 500px; /* Adjust width for fade effect */
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 2;
  }

  .left-fade {

    left: calc(100% - 1700px - 10vw);
  }

  .right-fade {
    right: calc(100% - 1700px - 10vw);
  }
}

@media only screen and (min-width: 1975px) {
  /* Fade Overlay Elements */
  .left-fade,
  .right-fade {
    position: absolute;
    top: 0;
    width: 500px; /* Adjust width for fade effect */
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 2;
  }

  .left-fade {

    left: calc(100% - 1975px - 8vw);
  }

  .right-fade {
    right: calc(100% - 1975px - 8vw);
  }
}