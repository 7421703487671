@import '../../variables.scss';

.DropdownSelect {
  position: relative;
  display: flex;
  align-items: center;

  .DropdownSelectContent {
    position: absolute;
    display: none;
    top: 100%;
    left: -2px;
    min-width: 160px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    z-index: 1; 
    color: $white;
    user-select: none;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid $contrast-dark;
 
    @media (max-width: $breakpoint-mobile) {
      & {
        width: 101%;
      }
    }

    .Option {
      color: $black;
      padding: 5px 10px;
      cursor: pointer;

      @media (max-width: $breakpoint-mobile) {
        & {
          padding: 6px 10px;
        }
      }

      &:hover {
        background-color: $contrast-dark;
        color: #fff;
      }
    }
  }

  &.isOpen {
    .DropdownSelectContent {
      display: block;
    }
  }

  .SelectedTerm {
    margin-right: 5px;
    white-space: nowrap;
  }

  .Arrow:not(.Show) {
    border-right: 6px solid transparent;
    border-top: 10px solid #fff;
    border-left: 6px solid transparent;
  }
}
