@import '../../variables';

.Qreel .BrowseNavigation {
  display: none;
  visibility: hidden;
}

.BrowseNavigation {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .Arrow {
    margin-left: 8px;
    margin-top: 2px;
  }

  .dropdown-content {
    color: $dark-blue;
    border-radius: 5px;
    display: none;
    position: absolute;
    min-width: 15vw;
    padding: 20px;
    z-index: 1;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    text-transform: uppercase;
    user-select: none;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

    .Dropdown-Item {
      margin-bottom: 0.3em;
      color: $black;
      font-family: $font-family-metropolis;
      font-size: 110%;
      white-space: nowrap;
      
      &:last-child {
        margin-bottom: unset;
      }

      &:hover {
        color: $contrast;
      }
    }
  }

  &:hover {
    .Arrow {
      border-top: unset;
      border-bottom: $arrow-color;
    }

    .dropdown-content {
      display: flex;
    }
  }
}
